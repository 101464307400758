import styled from 'styled-components';
import { Drawer } from 'antd';

interface DrawerWrapperProps {
  width?: string;
  authInput? : boolean;
  marginBottom? : string | undefined
}

const DrawerWrapper = styled(Drawer)<DrawerWrapperProps>`
    .ant-drawer-header{
        padding:24px 16px;
        .ant-drawer-title{
            margin-left: -10px;
        }
    }
    &.add-template-drawer-wrapper {
        .ant-drawer-header {
        padding-block: 8px;
}
.ant-drawer-body {
    padding: 16px;
}
.add-scroll {
    height: calc(100vh - 210px) !important;
}
.card-ui-wrapper {
    height: calc(100lvh - 26vh) !important;
}
    }
`;

export default DrawerWrapper;
