import React, { ChangeEvent } from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import Input from '../../input/index';
import InfoIcon from '../../../assets/images/info.svg';
import ChatInfo from '../../../assets/images/chat-info.svg';
import Switch from '../../switch';

import { ConfigurationWrapper } from './style';

import { SetCampaignState } from '../../../redux/slices/campaign';
import { CampaignState } from '../../../redux/types/campaign';

interface ConfigurationProps {
  campaignHelperText: any;
  setCampaignHelperText: (value: any) => void;
}

const Configuration: React.FC<ConfigurationProps> = ({
  campaignHelperText,
  setCampaignHelperText
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { newCampaign } = useSelector((state: { campaign: CampaignState }) => state.campaign);

  const content = (
    <div className="add-max-width">
      <p>{t('after_how_many_days_post_order')}</p>
      <p>{t('after_how_many_days_post_order_second_line')}</p>
      <p>{t('after_how_many_days_post_order_third_line')}</p>
    </div>
  );

  const contentFrequency = (
    <div className="add-max-width">
      <p>{t('choose_by_frequency')}</p>
      <p>{t('choose_by_frequency_second_line')}</p>
      <p>{t('choose_by_frequency_third_line')}</p>
    </div>
  );

  const contentQuantity = (
    <div className="add-max-width">
      <p>{t('stop_the_campaign_automatically')}</p>
    </div>
  );

  const onBoardingPhase = (
    <div className="add-max-width">
      <p>
        {t('with_the_onboarding_function')}
      </p>
      <p>
        {t('with_the_onboarding_function_second_line')}
      </p>
      <p>
        {t('with_the_onboarding_function_third_line')}
      </p>
    </div>
  );

  const startImmediatelyText = (
    <div className="add-max-width">
      <p>
        {t('start_immediately_text')}
      </p>
    </div>
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    const reg = /^\d+$/;
    if (reg.test(e.target.value) || !e.target.value) {
      let { value = '' } = e.target;
      if (value && Number(value) > 1000) {
        value = '1000';
      }

      dispatch(SetCampaignState({
        field: 'newCampaign',
        value: {
          ...newCampaign,
          [key]: !value ? '' : Number(value)
        }
      }));

      setCampaignHelperText({
        ...campaignHelperText,
        [key]: ''
      });
    }
  };

  return (
    <div className="add-scroll">
      <ConfigurationWrapper>
        <div className="info-box">
          <div>
            <img src={InfoIcon} alt={t('no_info_icon')} />
          </div>
          <div className="d-flex info-caption">
            <span>{t('configuration_point_1')}</span>
            <span>{t('configuration_point_2')}</span>
            <span>{t('configuration_point_3')}</span>
          </div>
        </div>
        <div className="input-box">
          <div className="input-header">
            {t('timing')}
            <Popover placement="topLeft" className="control-width pl-1" content={content}>
              <span className="tooltip"><img src={ChatInfo} alt={t('no_info')} /></span>
            </Popover>
          </div>
          <div className="input-body">
            <Input
              width="265px"
              placeholder={t('enter_days')}
              onChange={(e: any) => handleInputChange(e, 'timing')}
              value={newCampaign?.timing || ''}
              helperText={campaignHelperText?.timing}
            />
          </div>
        </div>
        <div className="input-box">
          <div className="input-header">
            {t('frequency')}
            <Popover placement="topLeft" className="control-width pl-1" content={contentFrequency}>
              <span className="tooltip"><img src={ChatInfo} alt={t('no_info')} /></span>
            </Popover>
          </div>
          <div className="input-body">
            <Input
              width="265px"
              placeholder={t('enter_letter_interval')}
              onChange={(e: any) => handleInputChange(e, 'letterIntervalFrequency')}
              value={newCampaign?.letterIntervalFrequency || ''}
              helperText={campaignHelperText?.letterIntervalFrequency}
            />
          </div>
        </div>
        <div className="input-box">
          <div className="input-header">
            {t('onboarding_phase')}
            <Popover placement="topLeft" className="control-width pl-1" content={onBoardingPhase}>
              <span className="tooltip"><img src={ChatInfo} alt={t('no_info')} /></span>
            </Popover>
          </div>
          <div className="input-body">
            <Switch
              onChange={(e: any) => dispatch(SetCampaignState({
                field: 'newCampaign',
                value: {
                  ...newCampaign,
                  onboardingPhase: e
                }
              }))}
              value={newCampaign?.onboardingPhase}
              label={t('one_month_past_data')}
            />
          </div>
        </div>
        <div className="input-box">
          <div className="input-header">
            {t('quantity')}
            <Popover placement="topLeft" className="control-width pl-1" content={contentQuantity}>
              <span className="tooltip"><img src={ChatInfo} alt={t('no_info')} /></span>
            </Popover>
          </div>
          <div className="input-body d-flex align-items-center add-space-between-switch">
            <Input
              width="265px"
              placeholder={t('enter_max_letters')}
              onChange={(e: any) => handleInputChange(e, 'lettersQuantity')}
              value={newCampaign?.lettersQuantity || ''}
              helperText={campaignHelperText?.lettersQuantity}
            />
          </div>
        </div>
        <div className="input-box">
          {
              !['Active', 'Pending'].includes(newCampaign?.status) && (
              <div className="input-box">
                <div className="input-header">
                  {t('start')}
                  <Popover placement="topLeft" className="control-width pl-1" content={startImmediatelyText}>
                    <span className="tooltip"><img src={ChatInfo} alt={t('no_info')} /></span>
                  </Popover>
                </div>

                <div className="input-body d-flex align-items-center add-space-between-switch">
                  <Switch
                    onChange={(e: any) => dispatch(SetCampaignState({
                      field: 'newCampaign',
                      value: {
                        ...newCampaign,
                        startImmediately: e
                      }
                    }))}
                    value={newCampaign?.startImmediately}
                    label={t('start_immediately')}
                  />
                </div>
              </div>
              )
            }
        </div>
      </ConfigurationWrapper>
    </div>
  );
};

export default Configuration;
