/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';

import PlusIcon from '../../assets/icons/add-icon-1.svg';
import CheckIcon from '../../assets/icons/payment-check-gold.svg';
import FaTimeIcon from '../../assets/icons/crose-icon-1.svg';
import InfoIcon from '../../assets/icons/chat-info-1.svg';

import Button from '../../components/button';
import Modal from '../../components/warning-modal';
import DeleteModal from '../templates/modals/delete';
import Loader from '../../components/loader';
import PaymentCard from '../../components/payment-card';
import PaymentModal from './modal/payment-card';
import PriceCard from '../../components/price-card';

import { GetPaymentPlans } from '../../redux/slices/payment-plan';
import {
  GetPaymentCards,
  SetPaymentState
} from '../../redux/slices/payment';
import {
  CancelSubscription,
  DeleteCard,
  SetCardAsDefault,
  SetStripeState
} from '../../redux/slices/stripe';

import { PaymentState } from '../../redux/types/payment';
import { PaymentPlanState } from '../../redux/types/payment-plan';
import { StripeState } from '../../redux/types/stripe';
import { UserState } from '../../redux/types/user';

import {
  ConnectSocket,
  DisconnectSocket,
  HandleCurrentUser,
  HandleGetPaymentCards
} from '../../utils/socket';
import { SubItem } from '../../utils/types/pages/payment-card';

import PaymentMethodWrapper from './style';

interface PurchasedPlanType {
  category: string;
  planType: string;
  productId: string;
  name: string;
  totalLetter: number;
  pricePerLetter: number;
  totalPrice: number;
  pricePerMonth: number;
}

const publishKey = process.env.REACT_APP_BRIEF_ADLER_STRIPE_PUBLISH_KEY || '';
const stripePromise = loadStripe(publishKey);
const reactAppSocketServer = process.env.REACT_APP_API_SOCKET_SERVER;

const PaymentMethod = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    defaultCardId,
    paymentCards,
    loading
  } = useSelector((state: { payment: PaymentState }) => state.payment) || {};
  const { paymentPlans } = useSelector((state: { paymentPlan: PaymentPlanState }) => state.paymentPlan) || {};
  const {
    cardDeleted,
    defaultCard,
    loading: stripeLoading,
    cancelSubscriptionLoading,
    cancelSubscriptionSuccess
  } = useSelector((state: { stripe: StripeState }) => state.stripe) || {};
  const { user = {} } = useSelector((state: { user: UserState }) => state.user) || {};

  const [cardToDeleteId, setCardToDeleteId] = useState<string>('');
  const [openCancelSubscriptionWarning, setOpenCancelSubscriptionWarning] = useState(false);
  const [paymentModal, setPaymentModal] = useState<boolean>(false);
  const [isPurchasedPlan, setIsPurchasedPlan] = useState<boolean>(false);
  const [deleteCard, setDeleteCard] = useState(false);
  const [defaultPaymentCard, setDefaultPaymentCard] = useState<string>('');
  const [editCard, setEditCard] = useState<string>('add');
  const [updateCardId, setUpdateCardId] = useState<string>('');
  const [purchasedPlan, setPurchasedPlan] = useState<PurchasedPlanType | undefined>();
  const [editCardDetails, setEditCardDetails] = useState<object>({
    name: '',
    country: '',
    postalCode: ''
  });

  const ITEMS: SubItem[] = [{
    heading: t('features'),
    subItems: [{
      name: t('addressing_customer'),
      title: t('addressing_customer_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }, {
      name: t('personalization'),
      title: t('personalization_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }, {
      name: t('variables'),
      title: t('variables_info'),
      imagePath: InfoIcon,
      descriptionImage: FaTimeIcon
    }, {
      name: t('expert_view'),
      title: t('expert_view_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }, {
      name: t('print_pages'),
      title: t('print_pages_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }, {
      name: t('insert_magazines'),
      title: t('insert_magazines_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }]
  }, {
    heading: t('software_evaluation'),
    subItems: [{
      name: t('brief_adler_software'),
      title: t('brief_adler_software_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('fully_automated_processing'),
      title: `Enjoy a fully automated process with connecting
           your Seller Central via our API interface to take advantage
           of our automated letter distribution. All order data is automatically
           fetched and the letters are sent fully automated.
            Don't want integration? No problem, we also offer manual CSV file uploads of address data.`,
      imagePath: InfoIcon,
      descriptionImage: CheckIcon
    }, {
      name: t('filtering_and_cancellations'),
      title: t('filtering_and_cancellations_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('dashboard_quota'),
      title: t('dashboard_quota_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('qr_code_tracking'),
      title: t('qr_code_tracking_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }]
  }
  ];

  const ITEMS1: SubItem[] = [{
    heading: t('features'),
    subItems: [{
      name: t('addressing_customer'),
      title: t('addressing_customer_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon

    }, {
      name: t('personalization'),
      title: t('personalization_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('variables'),
      title: t('variables_info'),
      imagePath: InfoIcon,
      descriptionImage: CheckIcon
    }, {
      name: t('expert_view'),
      title: t('expert_view_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
      // }, {
      //   name: t('print_pages'),
      //   title: t('print_pages_info'),
      //   descriptionImage: CheckIcon,
      //   imagePath: InfoIcon
      // }, {
      //   name: t('insert_magazines'),
      //   title: t('insert_magazines_info'),
      //   descriptionImage: FaTimeIcon,
      //   imagePath: InfoIcon
    }, {
      name: t('gummy_bears'),
      title: t('gummy_bears_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }]
  }, {
    heading: t('software_evaluation'),
    subItems: [{
      name: t('brief_adler_software'),
      title: t('brief_adler_software_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('fully_automated_processing'),
      title: `Enjoy a fully automated process with connecting
           your Seller Central via our API interface to take advantage
           of our automated letter distribution. All order data is automatically
           fetched and the letters are sent fully automated.
            Don't want integration? No problem, we also offer manual CSV file uploads of address data.`,
      imagePath: InfoIcon,
      descriptionImage: CheckIcon
    }, {
      name: t('filtering_and_cancellations'),
      title: t('filtering_and_cancellations_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('dashboard_quota'),
      title: t('dashboard_quota_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('qr_code_tracking'),
      title: t('qr_code_tracking_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }]
  }];

  const ITEMS2: SubItem[] = [{
    heading: t('features'),
    subItems: [{
      name: t('addressing_customer'),
      title: t('addressing_customer_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon

    }, {
      name: t('personalization'),
      title: t('personalization_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('variables'),
      title: t('variables_info'),
      imagePath: InfoIcon,
      descriptionImage: CheckIcon
    }, {
      name: t('expert_view'),
      title: t('expert_view_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
      // }, {
      //   name: t('print_pages'),
      //   title: t('print_pages_info'),
      //   descriptionImage: CheckIcon,
      //   imagePath: InfoIcon
      // }, {
      //   name: t('insert_magazines'),
      //   title: t('insert_magazines_info'),
      //   descriptionImage: CheckIcon,
      //   imagePath: InfoIcon
    }, {
      name: t('gummy_bears'),
      title: t('gummy_bears_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }]
  }, {
    heading: t('software_evaluation'),
    subItems: [{
      name: t('brief_adler_software'),
      title: t('brief_adler_software_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('fully_automated_processing'),
      title: `Enjoy a fully automated process with connecting
           your Seller Central via our API interface to take advantage
           of our automated letter distribution. All order data is automatically
           fetched and the letters are sent fully automated.
            Don't want integration? No problem, we also offer manual CSV file uploads of address data.`,
      imagePath: InfoIcon,
      descriptionImage: CheckIcon
    }, {
      name: t('filtering_and_cancellations'),
      title: t('filtering_and_cancellations_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('dashboard_quota'),
      title: t('dashboard_quota_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('qr_code_tracking'),
      title: t('qr_code_tracking_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }]
  }];

  const handleEditCardModal = (card: any, cardId: string) => {
    setEditCardDetails({
      name: card?.name,
      country: card?.country,
      postalCode: card?.postalCode
    });

    setEditCard('edit');
    setPaymentModal(true);
    setUpdateCardId(cardId);
  };

  const handleSetAsDefault = (cardId: string) => {
    setDefaultPaymentCard(cardId);
    dispatch(SetCardAsDefault({ cardId }));
  };

  const handleDeleteModal = (cardId: string) => {
    setDeleteCard(true);
    setCardToDeleteId(cardId);
  };

  const handleCardDelete = () => {
    dispatch(DeleteCard({ cardId: cardToDeleteId }));
    setDeleteCard(false);
  };

  const handleCancelSubscription = () => {
    dispatch(CancelSubscription());
  };

  useEffect(() => {
    if (cancelSubscriptionSuccess) {
      setOpenCancelSubscriptionWarning(false);
    }
  }, [cancelSubscriptionSuccess]);

  useEffect(() => {
    const socket = ConnectSocket({
      url: reactAppSocketServer || '',
      userId: user?._id || ''
    });

    HandleGetPaymentCards(socket, dispatch);
    HandleCurrentUser(socket, dispatch);

    dispatch(GetPaymentPlans({}));
    dispatch(GetPaymentCards());

    return () => {
      DisconnectSocket(socket);
    };
  }, []);

  useEffect(() => {
    if (defaultCard) {
      dispatch(SetPaymentState({ field: 'defaultCardId', value: defaultPaymentCard }));
      dispatch(SetStripeState({ field: 'defaultCard', value: false }));
    } else if (cardDeleted) {
      dispatch(SetStripeState({ field: 'cardDeleted', value: false }));
    }
  }, [defaultCard, cardDeleted]);

  useEffect(() => {
    if (paymentPlans?.length) {
      const pPlan = paymentPlans?.find((obj: any) => obj.productId === user?.purchasedBundle?.purchasedProductId);
      const isPurchased = !!purchasedPlan;

      setIsPurchasedPlan(isPurchased);
      setPurchasedPlan(pPlan);
    }
  }, [paymentPlans, user]);

  return (
    <PaymentMethodWrapper>
      {(loading || stripeLoading) && <Loader />}
      <header>
        <h2>{t('payment_method')}</h2>
        <Button
          text={t('add_card')}
          icon={
            <img width="14px" src={PlusIcon} alt="PlusIcon" />
          }
          type="primary"
          onClick={() => setPaymentModal(true)}
        />
      </header>
      <section>
        <div className="boxes-wrapper">
          {paymentCards?.length
            ? paymentCards.map((card: any, index: number) => (
              <PaymentCard
                key={index}
                title={card.name}
                description={`xxxx-xxxx-xxxx-${card?.cardLast4Digits}`}
                date={`${card?.expMonth}/${card?.expYear?.toString()?.slice(2, card?.expYear?.toString()?.length)}`}
                editButton={() => handleEditCardModal(card, card.cardId)}
                deleteButton={() => handleDeleteModal(card.cardId)}
                isActive={card.cardId === defaultCardId}
                onClick={() => handleSetAsDefault(card.cardId)}
              />
            ))
            : <></>}
        </div>
        {(purchasedPlan?.category === 'Basic' && user?.purchasedBundle?.subscriptionId) && (
          <PriceCard
            items={ITEMS}
            planText="Basic"
            planPrice={purchasedPlan?.pricePerLetter ? `${purchasedPlan?.pricePerLetter}€` : '780€'}
            cardHeading="Reach your customers as cost-effectively and personally as possible."
            cardSubHeading="Letter General"
            cardDisc1="Letterhead: DIN A5"
            cardDisc2="Envelope: DIN C6 white"
            cardDisc3="Number of pages: 1"
            cardDisc4="Number of characters: unlimited"
            user={user}
            isPurchasedPlan={isPurchasedPlan}
            purchasedPlan="Basic"
            setOpenCancelSubscriptionWarning={setOpenCancelSubscriptionWarning}
          />
        )}
        {(purchasedPlan?.category === 'Branding' && user?.purchasedBundle?.subscriptionId) && (
          <PriceCard
            items={ITEMS1}
            planText="Branding"
            planPrice={purchasedPlan?.pricePerLetter ? `${purchasedPlan?.pricePerLetter}€` : '300€'}
            cardHeading="Send letters that are even more personal and rely on premium-quality materials."
            cardSubHeading="Letter General"
            cardDisc1="Letterhead: DIN A5"
            cardDisc2="Envelope: DIN C6 white"
            cardDisc3="Number of pages: 1"
            cardDisc4="Number of characters: unlimited"
            user={user}
            isPurchasedPlan={isPurchasedPlan}
            purchasedPlan="Branding"
            setOpenCancelSubscriptionWarning={setOpenCancelSubscriptionWarning}
          />
        )}
        {(purchasedPlan?.category === 'Maxi' && user?.purchasedBundle?.subscriptionId) && (
          <PriceCard
            items={ITEMS2}
            planText="Plus"
            planPrice={purchasedPlan?.pricePerLetter ? `${purchasedPlan?.pricePerLetter}€` : '500€'}
            cardHeading="The Plus packaging allows the addition of inserts, such as brochures,
            catalogs, small gifts, samples or informational materials."
            cardSubHeading="Letter General"
            cardDisc1="Letterhead: DIN A5"
            cardDisc2="Envelope: DIN C6 white"
            cardDisc3="Number of pages: 1"
            cardDisc4="Number of characters: unlimited"
            user={user}
            isPurchasedPlan={isPurchasedPlan}
            purchasedPlan="Maxi"
            setOpenCancelSubscriptionWarning={setOpenCancelSubscriptionWarning}
          />
        )}
      </section>
      <DeleteModal
        heading={t('delete_card_description')}
        open={deleteCard}
        onCancel={() => setDeleteCard(false)}
        onConfirm={() => handleCardDelete()}
      />
      <Modal
        heading={t('cancel_subscription_heading')}
        onCancel={() => {
          setOpenCancelSubscriptionWarning(false);
        }}
        saveText={t('confirm')}
        onConfirm={() => {
          handleCancelSubscription();
        }}
        open={openCancelSubscriptionWarning}
        loading={cancelSubscriptionLoading}
      />
      <Elements stripe={stripePromise}>
        <PaymentModal
          open={paymentModal}
          setPaymentModal={setPaymentModal}
          editCard={editCard}
          setEditCard={setEditCard}
          updateCardId={updateCardId}
          setEditCardDetails={setEditCardDetails}
          editCardDetails={editCardDetails}
        />
      </Elements>
    </PaymentMethodWrapper>
  );
};

export default PaymentMethod;
