/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import moment from 'moment';
import React, {
  useEffect,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { isEmpty } from 'lodash';
import { FaPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

import ActionButtons from './actions-button';

import AddMaxiAddressModal from './modals/add-maxi-address';
import UploadModal from './modals/upload';
import Warning from './modals/warning';
import Clone from './modals/clone';
import SelectCampaign from './modals/select-campaign';
import ConnectAmazon from './modals/connect-seller-account';
import CheckModal from './modals/check';

import Button from '../../components/button';
import DrawerData from '../../components/auto-campaign-drawer';
import Drawer from '../../components/drawer';
import Loader from '../../components/loader';
import QuickTutorial from '../../components/modal/quick-tutorial';
import Table from '../../components/table';
import Pagination from '../../components/pagination';
import { ManualCampaignsFilterComponent } from '../../components/filter-column-header';
import { ManualCampaignsSearchComponent } from '../../components/search-column-header';

import CampaignsWrapper from './style';

import {
  AddAutoCampaign as AddAutoCampaignAPI,
  BlockCampaign,
  CompleteCampaign,
  DeleteCampaignById,
  GetAllCampaigns,
  PauseOrStopAutoCampaign,
  SetCampaignState,
  StartCampaign,
  UpdateAutoCampaign
} from '../../redux/slices/campaign';
import { GetProductsForAutoCampaign, SetProductState } from '../../redux/slices/product';
import {
  EditMaxiInsert,
  GetMaxiInsertByTemplateId
} from '../../redux/slices/maxi';
import { SetOtherNotifyState } from '../../redux/slices/other';
import { GetConnectedStore } from '../../redux/slices/store';
import {
  Campaign,
  CampaignFilters,
  CampaignState,
  SortBy
} from '../../redux/types/campaign';
import { MaxiInsertState } from '../../redux/types/maxi';
import {
  GetAllTemplatesWithSheets,
  GetTemplateForEmployee
} from '../../redux/slices/template';
import { TemplateState } from '../../redux/types/template';
import { StoreState } from '../../redux/types/store';
import { UpdateUser } from '../../redux/slices/user';
import { UserState } from '../../redux/types/user';

import {
  ConnectSocket,
  DisconnectSocket,
  HandleCampaignProgress,
  HandleNotificationCreation
} from '../../utils/socket';

import {
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_FILTER,
  CAMPAIGN_TYPES_FILTER,
  PAGE_SIZES
} from '../../constants';

interface CellRendererProps {
  value: string | undefined;
}

const reactAppSocketServer = process.env.REACT_APP_API_SOCKET_SERVER;

const TemplateNameCustomCellRenderer:React.FC<CellRendererProps> = ({ value }) => {
  if (value) {
    return (
      value.length > 30 ? <Tooltip title={value}>{value}</Tooltip> : value
    );
  }

  return 'N/A';
};

const CampaignsTable: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    campaigns,
    newCampaign,
    currentCampaign,
    clonedCampaign,
    deleteCampaignLoading,
    getAllCampaignsSuccess,
    isCampaignAdded,
    isCampaignDeleted,
    isCampaignMarkedAsCanBeStarted,
    markAsCanBeStartedLoading,
    isCampaignStarted,
    loading,
    startCampaignLoading,
    blockCampaignLoading,
    isCampaignBlocked,
    totalCampaigns,
    isCampaignCloned,
    isCampaignUpdated
  } = useSelector((state: { campaign: CampaignState }) => state.campaign);
  const {
    getMaxiInsertByTemplateIdSuccess,
    isMaxiInsertEdited,
    maxiInsert
  } = useSelector((state: { maxiInsert: MaxiInsertState }) => state.maxiInsert);
  const { templatesWithSheets } = useSelector((state: { template: TemplateState }) => state.template);
  const { store } = useSelector((state: { store: StoreState }) => state.store);
  const {
    getCurrentUserSuccess,
    user
  } = useSelector((state: { user: UserState }) => state.user);

  const [blockCampaign, setBlockCampaign] = useState(false);
  const [blockCampaignId, setBlockCampaignId] = useState<string | null>('');
  const [usedTemplateId, setUsedTemplateId] = useState<string | null>('');
  const [openAddMaxiAddressModal, setOpenAddMaxiAddressModal] = useState(false);
  const [isDataRetrievalSuccessful, setIsDataRetrievalSuccessful] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<number>(PAGE_SIZES[0]);
  const [upload, setUpload] = useState(false);
  const [openEditOneTimeCampaign, setOpenEditOneTimeCampaign] = useState(false);
  const [data, setData] = useState<Campaign[]>([]);
  const [deleteCampaign, setDeleteCampaign] = useState(false);
  const [markAsCanBeStarted, setMarkAsCanBeStarted] = useState(false);
  const [templateWarning, setTemplateWarning] = useState(false);
  const [deleteCampaignId, setDeleteCampaignId] = useState<string | null>('');
  const [canBeStartedId, setCanBeStartedId] = useState<string | null>('');
  const [startCampaign, setStartCampaign] = useState(false);
  const [startCampaignId, setStartCampaignId] = useState<string | null>('');
  const [completeCampaignId, setCompleteCampaignId] = useState<string | null>('');
  const [cloneCampaign, setCloneCampaign] = useState(false);
  const [cloneCampaignId, setCloneCampaignId] = useState<string | undefined>('');
  const [editCampaignId, setEditCampaignId] = useState<string>('');
  const [pauseOrStopCampaignId, setPauseOrStopCampaignId] = useState<string>('');
  const [pauseOrStopCampaignStatus, setPauseOrStopCampaignStatus] = useState<string>('');
  const [campaignAction, setCampaignAction] = useState<string>('');
  const [completeCampaign, setCompleteCampaign] = useState(false);
  const [sellerAccount, setSellerAccount] = useState(false);
  const [pauseOrStopCampaign, setPauseOrStopCampaign] = useState(false);
  const [campaignCreatedModal, setCampaignCreatedModal] = useState(false);
  const [quickTutorial, setQuickTutorial] = useState<boolean>(false);
  const [videoPlayerModal, setVideoPlayerModal] = useState<boolean>(false);
  const [videoPlayerModalLink, setVideoPlayerModalLink] = useState<string>('');
  const [isLoginFirstTime, setIsLoginFirstTime] = useState(false);
  const [sortValue, setSortValue] = useState<SortBy | null>({});
  const [step, setStep] = useState<number>(0);
  const [productsData, setProductsData] = useState<any>([]);
  const [filters, setFilters] = useState<CampaignFilters>({
    searchByKeyWords: {
      type: '',
      status: '',
      name: '',
      templateName: '',
      letterIntervalFrequency: '',
      progress: '',
      createdAt: ''
    }
  });

  interface StatusCellRendererParams {
    value: string;
  }

  const statusDetail: {
    Pending: string;
    Active: string;
    Paused: string;
    Stopped: string;
    Completed: string;
    Blocked: string;
    Draft: string;
  } = {
    Pending: t('pending_status_details'),
    Active: t('active_status_details'),
    Paused: t('paused_status_details'),
    Stopped: t('stopped_status_details'),
    Completed: t('completed_status_details'),
    Blocked: t('blocked_status_details'),
    Draft: t('draft_status_details')
  };

  type StatusKey = keyof typeof statusDetail;

  const isStatusKey = (value: string): value is StatusKey => value in statusDetail;

  const getStatusCellRenderer = (params: StatusCellRendererParams) => {
    const { value } = params;

    let statusClass = '';

    switch (value) {
      case CAMPAIGN_STATUS.ACTIVE:
        statusClass = 'status-completed';
        break;
      case CAMPAIGN_STATUS.COMPLETED:
        statusClass = 'status-completed-grey';
        break;
      case CAMPAIGN_STATUS.STOPPED: case CAMPAIGN_STATUS.PAUSED:
        statusClass = 'status-archived';
        break;
      case CAMPAIGN_STATUS.PENDING:
        statusClass = 'status-pending';
        break;
      case CAMPAIGN_STATUS.DRAFT:
        statusClass = 'status-completed-grey';
        break;
      case CAMPAIGN_STATUS.CAN_BE_STARTED:
        statusClass = 'status-can-be-started';
        break;
      case CAMPAIGN_STATUS.BLOCKED:
        statusClass = 'status-blocked';
        break;
      default:
        statusClass = '';
    }

    const tooltipTitle = isStatusKey(value) ? statusDetail[value] : '';

    return <Tooltip title={tooltipTitle}><span className={`status-cell ${statusClass}`}>{value}</span></Tooltip>;
  };

  const getProgressCellRenderer = (params: { data: Campaign }) => {
    const {
      ordersProcessed,
      lettersQuantity,
      status,
      records,
      totalRecords,
      type,
      letterIntervalFrequency = 1
    } = params.data;

    if (type === 'Auto') {
      if (!lettersQuantity && !ordersProcessed) {
        return 'N/A';
      }

      if (lettersQuantity && !ordersProcessed) {
        return `0 / ${lettersQuantity * letterIntervalFrequency}`;
      }

      if (lettersQuantity) {
        return `${ordersProcessed} / ${lettersQuantity * letterIntervalFrequency}`;
      }
    }

    if (type === 'One Time') {
      if (status === 'Blocked') {
        return `${records} / ${totalRecords}`;
      }

      if (status !== 'Completed') {
        return `0 / ${records}`;
      }

      return `${records} / ${records}`;
    }

    return 'N/A';
  };

  const actionCellRenderer = (params: { data: Campaign }) => {
    const {
      _id: campaignActionId,
      status
    } = params.data;

    return (
      <ActionButtons
        campaignId={campaignActionId}
        status={status}
        setClone={setCloneCampaign}
        setCloneCampaignId={setCloneCampaignId}
        setDeleteCampaign={setDeleteCampaign}
        setMarkAsCanBeStarted={setMarkAsCanBeStarted}
        setCanBeStartedId={setCanBeStartedId}
        setDeleteCampaignId={setDeleteCampaignId}
        setStartCampaign={setStartCampaign}
        setStartCampaignId={setStartCampaignId}
        setPauseOrStopCampaign={setPauseOrStopCampaign}
        setEditCampaignId={setEditCampaignId}
        setCampaignAction={setCampaignAction}
        setPauseOrStopCampaignId={setPauseOrStopCampaignId}
        setPauseOrStopCampaignStatus={setPauseOrStopCampaignStatus}
        setBlockCampaign={setBlockCampaign}
        setBlockCampaignId={setBlockCampaignId}
        setOpenEditOneTimeCampaign={setOpenEditOneTimeCampaign}
      />
    );
  };

  const CustomCellRenderer:React.FC<CellRendererProps> = ({ value }) => {
    if (!value) {
      return 'N/A';
    }
    return value;
  };

  const blockCampaignById = () => {
    if (!isEmpty(blockCampaignId)) {
      dispatch(BlockCampaign({
        campaignId: blockCampaignId as string
      }));
    }
  };

  const deleteCampaignById = () => {
    if (!isEmpty(deleteCampaignId)) {
      dispatch(DeleteCampaignById({
        campaignId: deleteCampaignId as string
      }));
    }

    setDeleteCampaignId(null);
  };

  const markCampaignAsCanBeStarted = () => {
    if (!isEmpty(canBeStartedId)) {
      dispatch(StartCampaign({
        campaignId: canBeStartedId as string
      }));
    }

    setCanBeStartedId(null);
    setMarkAsCanBeStarted(false);
  };

  const getAllCampaigns = ({ applyFilters = filters }) => {
    const skip = (pageNumber - 1) * pageLimit;
    const limit = pageLimit;

    dispatch(
      GetAllCampaigns({
        skip,
        limit,
        sortBy: sortValue || undefined,
        filters: applyFilters
      })
    );
  };

  const startCampaignById = () => {
    if (!isEmpty(startCampaignId)) {
      dispatch(StartCampaign({
        campaignId: startCampaignId as string
      }));
    }

    setStartCampaignId(null);
  };

  const completeCampaignById = () => {
    if (!isEmpty(completeCampaignId)) {
      dispatch(CompleteCampaign({
        campaignId: completeCampaignId as string
      }));
    }

    setCompleteCampaignId(null);
  };

  const columnDefs = [{
    headerName: t('campaign_name'),
    field: 'name',
    headerComponent: ManualCampaignsSearchComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'name',
      filters,
      setFilters,
      getAllCampaigns,
      showArrow: sortValue?.name === 'asc'
        ? 'up'
        : sortValue?.name === 'desc'
          ? 'down'
          : null
    },
    minWidth: 180,
    // pinned: 'left',
    flex: 1,
    cellRenderer: CustomCellRenderer
  }, {
    headerName: t('status'),
    field: 'status',
    cellRenderer: getStatusCellRenderer,
    headerComponent: ManualCampaignsFilterComponent,
    headerComponentParams: {
      tooltip: true,
      setSortValue,
      fieldName: 'status',
      filters,
      options: CAMPAIGN_STATUS_FILTER,
      setFilters,
      getAllCampaigns,
      showArrow: sortValue?.status === 'asc'
        ? 'up'
        : sortValue?.status === 'desc'
          ? 'down'
          : null
    },
    minWidth: 180,
    flex: 1
  }, {
    headerName: t('progress'),
    cellRenderer: getProgressCellRenderer,
    field: 'progress',
    minWidth: 150,
    flex: 1
  }, {
    headerName: t('frequency_column'),
    field: 'letterIntervalFrequency',
    headerComponent: ManualCampaignsSearchComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'letterIntervalFrequency',
      filters,
      setFilters,
      getAllCampaigns,
      showArrow: sortValue?.letterIntervalFrequency === 'asc'
        ? 'up'
        : sortValue?.letterIntervalFrequency === 'desc'
          ? 'down'
          : null
    },
    minWidth: 150,
    flex: 1,
    cellRenderer: CustomCellRenderer
  }, {
    headerName: t('type'),
    sortable: true,
    filter: true,
    field: 'type',
    headerComponent: ManualCampaignsFilterComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'type',
      filters,
      options: CAMPAIGN_TYPES_FILTER,
      setFilters,
      getAllCampaigns,
      showArrow: sortValue?.type === 'asc'
        ? 'up'
        : sortValue?.type === 'desc'
          ? 'down'
          : null
    },
    minWidth: 180,
    flex: 1
  }, {
    headerName: t('template_name'),
    field: 'templateName',
    headerComponent: ManualCampaignsSearchComponent,
    headerComponentParams: {
      templateDropdown: true,
      setSortValue,
      fieldName: 'templateName',
      filters,
      setFilters,
      getAllCampaigns,
      showArrow: sortValue?.templateName === 'asc'
        ? 'up'
        : sortValue?.templateName === 'desc'
          ? 'down'
          : null
    },
    minWidth: 180,
    flex: 1,
    cellRenderer: TemplateNameCustomCellRenderer
  }, {
    headerName: t('created_at'),
    field: 'createdAt',
    headerComponent: ManualCampaignsFilterComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'createdAt',
      filters,
      showFilter: false,
      setFilters,
      getAllCampaigns,
      showArrow: sortValue?.createdAt === 'asc'
        ? 'up'
        : sortValue?.createdAt === 'desc'
          ? 'down'
          : null
    },
    minWidth: 180,
    flex: 1
  }, {
    headerName: t('actions'),
    field: 'actions',
    minWidth: 130,
    // pinned: 'right',
    sortable: false,
    flex: 1,
    cellRenderer: actionCellRenderer
  }];

  const addMaxiAddressHandler = ({
    address
  }: {
    address: string;
  }) => {
    dispatch(EditMaxiInsert({
      maxiInsertId: maxiInsert?._id as string,
      updateParams: {
        address
      }
    }));
  };

  const onCancel = (
    setFormData: React.Dispatch<React.SetStateAction<{
      address: string;
    }>>,
    setFormHelperText: React.Dispatch<React.SetStateAction<{
      address: string;
    }>>
  ) => {
    setFormData({ address: '' });

    setFormHelperText({ address: '' });

    setOpenAddMaxiAddressModal(false);
  };

  const onSubmit = (
    address: string,
    setFormData: React.Dispatch<React.SetStateAction<{
      address: string;
    }>>,
    setFormHelperText: React.Dispatch<React.SetStateAction<{
      address: string;
    }>>
  ) => {
    setFormData({ address: '' });

    setFormHelperText({ address: '' });

    addMaxiAddressHandler({ address });

    setOpenAddMaxiAddressModal(false);
  };

  const onPageChange = (
    page: number,
    size: number
  ) => {
    setPageNumber(page);
    setPageLimit(size);
  };

  const handleAddAutoCampaign = () => {
    dispatch(SetCampaignState({
      field: 'newCampaign',
      value: {}
    }));

    if (templatesWithSheets.length === 0) {
      setTemplateWarning(true);

      return;
    }

    if (!store) {
      setSellerAccount(true);
    } else {
      setCampaignAction('add');
    }

    dispatch(GetProductsForAutoCampaign({
      filters: {},
      sortBy: undefined
    }));
  };

  const handlePauseOrStopCampaign = (campaignId: string, status: string) => {
    dispatch(PauseOrStopAutoCampaign({
      campaignId,
      status
    }));

    setPauseOrStopCampaign(false);
  };

  const handleQuickTutorial = (language?: string) => {
    dispatch(UpdateUser({
      userId: user?._id as string,
      updateParams: {
        language: language === 'English' ? 'en' : 'de'
      }
    }));

    if (language === 'English') setVideoPlayerModalLink('https://www.youtube.com/watch?v=topBLaz4zgk');
    else if (language === 'German') setVideoPlayerModalLink('https://www.youtube.com/watch?v=r9os9Q6t6Xc&t=3694s');

    setQuickTutorial(false);
    setVideoPlayerModal(true);
  };

  const getTemplateForEmployee = () => {
    const templateId = campaigns?.find((campaign) => campaign._id === editCampaignId)?.templateId;
    if (templateId && !isEmpty(templateId)) {
      dispatch(GetTemplateForEmployee({ templateId }));
    }
  };

  useEffect(() => {
    dispatch(GetAllTemplatesWithSheets({ editCampaignId }));
  }, []);

  useEffect(() => {
    let socket: any;
    if (getCurrentUserSuccess) {
      socket = ConnectSocket({
        url: reactAppSocketServer || '',
        userId: user?._id as string
      });
    }

    if (socket) {
      HandleNotificationCreation(socket, dispatch);

      HandleCampaignProgress(socket, dispatch);

      return () => {
        DisconnectSocket(socket);
      };
    }

    return () => {};
  }, [getCurrentUserSuccess]);

  useEffect(() => {
    if (user) {
      const {
        _id: userId,
        notification = {}
      } = user || {};
      const { loginFirstTime } = notification || {};
      if (loginFirstTime) {
        setQuickTutorial(true);
        setIsLoginFirstTime(true);
        dispatch(UpdateUser({
          userId: userId as string,
          updateParams: {
            notification: {
              ...user?.notification,
              loginFirstTime: true
            }
          }
        }));
      }
    }
  }, [user]);

  useEffect(() => {
    // if (isMounted.current) {
    //   isMounted.current = false;
    //   return;
    // }

    getAllCampaigns({ applyFilters: filters });
    dispatch(GetConnectedStore({}));
  }, [
    pageNumber,
    pageLimit,
    sortValue
  ]);

  useEffect(() => {
    if (campaigns?.length) {
      const rowData = campaigns.map((campaign) => ({
        ...campaign,
        createdAt: new Date(campaign.createdAt).toDateString()
      }));

      setData(rowData);
    } else {
      setData([]);
    }
  }, [campaigns]);

  useEffect(() => {
    if (isCampaignAdded) {
      if (newCampaign?.status !== 'Draft') setCampaignCreatedModal(true);
      getAllCampaigns({ applyFilters: filters });
      setCampaignAction('');

      setUsedTemplateId(newCampaign?.templateId as string);
      dispatch(SetCampaignState({
        field: 'isCampaignAdded',
        value: false
      }));
      dispatch(SetCampaignState({
        field: 'newCampaign',
        value: {}
      }));
      dispatch(SetCampaignState({
        field: 'currentCampaign',
        value: {}
      }));
    }

    if (isCampaignDeleted) {
      setDeleteCampaign(false);
      getAllCampaigns({ applyFilters: filters });
      dispatch(SetCampaignState({
        field: 'isCampaignDeleted',
        value: false
      }));
    }

    if (isCampaignMarkedAsCanBeStarted) {
      setMarkAsCanBeStarted(false);
      getAllCampaigns({ applyFilters: filters });
      dispatch(SetCampaignState({
        field: 'isCampaignMarkedAsCanBeStarted',
        value: false
      }));
    }

    if (isCampaignStarted) {
      setStartCampaign(false);
      setCompleteCampaign(false);
      setMarkAsCanBeStarted(false);
      getAllCampaigns({ applyFilters: filters });
      dispatch(SetCampaignState({
        field: 'isCampaignStarted',
        value: false
      }));
    }

    if (isCampaignBlocked) {
      setBlockCampaign(false);
      setBlockCampaignId(null);
      getAllCampaigns({ applyFilters: filters });
      dispatch(SetCampaignState({
        field: 'isCampaignBlocked',
        value: false
      }));
    }
  }, [
    isCampaignAdded,
    isCampaignDeleted,
    isCampaignStarted,
    isCampaignMarkedAsCanBeStarted,
    isCampaignBlocked
  ]);

  useEffect(() => {
    if (campaigns?.length) {
      const processedCampaigns = campaigns.map((campaign) => ({
        ...campaign,
        createdAt: moment(campaign?.createdAt).format('DD MMMM YYYY')
      }));
      setData(processedCampaigns);
    } else {
      setData([]);
    }

    if (isCampaignCloned) {
      getAllCampaigns({ applyFilters: filters });
      dispatch(SetCampaignState({
        field: 'newCampaign',
        value: clonedCampaign
      }));
      dispatch(SetCampaignState({
        field: 'isCampaignCloned',
        value: false
      }));
    }

    if (isCampaignUpdated) {
      getAllCampaigns({ applyFilters: filters });
      setEditCampaignId('');
      setCampaignAction('');
      dispatch(SetCampaignState({
        field: 'newCampaign',
        value: {}
      }));
      dispatch(SetCampaignState({
        field: 'currentCampaign',
        value: {}
      }));
      dispatch(SetCampaignState({
        field: 'clonedCampaign',
        value: {}
      }));
      dispatch(SetCampaignState({
        field: 'isCampaignUpdated',
        value: false
      }));
    }
  }, [
    campaigns,
    isCampaignUpdated,
    isCampaignAdded,
    isCampaignCloned
  ]);

  useEffect(() => {
    if (getAllCampaignsSuccess) {
      setIsDataRetrievalSuccessful(true);

      dispatch(SetCampaignState({
        field: 'getAllCampaignsSuccess',
        value: false
      }));
    }
  }, [getAllCampaignsSuccess]);

  useEffect(() => {
    if (getMaxiInsertByTemplateIdSuccess) {
      if (Number(maxiInsert?.receivedQuantity) - Number(maxiInsert?.letterSentQuantity) <= 0) {
        setOpenAddMaxiAddressModal(true);
      }
    }
  }, [getMaxiInsertByTemplateIdSuccess]);

  useEffect(() => {
    if (isMaxiInsertEdited) {
      setOpenAddMaxiAddressModal(false);
    }
  }, [isMaxiInsertEdited]);

  useEffect(() => {
    if (openEditOneTimeCampaign) {
      dispatch(SetCampaignState({
        field: 'currentCampaign',
        value: campaigns?.find((camp) => camp._id === editCampaignId)
      }));
      getTemplateForEmployee();
    }
  }, [openEditOneTimeCampaign]);

  const titleComponent = (
    <div className="drawer-tutorial-link d-flex align-items-center justify-content-between">
      <h5 className="drawer-heading m-0">{campaignAction === 'edit' ? t('edit_auto_campaign') : t('add_auto_campaign')}</h5>
    </div>
  );

  return (
    <>
      {loading && <Loader />}
      <CampaignsWrapper>
        <div className="content-header">
          <h2 className="heading">{t('campaigns')}</h2>
          <div className="d-flex button-box">
            {user?.role !== 'employee' && (
              <>
                <Button
                  width="155px"
                  type="default"
                  onClick={() => setUpload(true)}
                  icon={<FaPlus />}
                  text={t('campaign_upload')}
                />
                <Button
                  width="155px"
                  type="primary"
                  icon={<FaPlus />}
                  text={t('auto_campaign')}
                  onClick={handleAddAutoCampaign}
                />
              </>
            )}
          </div>
        </div>
        <Table
          rowData={data}
          columnDefs={columnDefs}
          sortable={false}
          resizable
          editable={false}
          filter={false}
          height="164px"
          rowHeight={36}
          headerHeight={40}
          className="custom-table"
          isDataRetrievalSuccessful={isDataRetrievalSuccessful}
        />
        <Pagination
          totalPages={totalCampaigns}
          currentPage={pageNumber}
          onChange={onPageChange}
          pageSize={pageLimit}
          pageSizes={PAGE_SIZES}
        />
      </CampaignsWrapper>
      <UploadModal
        open={upload || openEditOneTimeCampaign}
        onCancel={() => {
          setUpload(false);
          setOpenEditOneTimeCampaign(false);
          setEditCampaignId('');
        }}
        editCampaignId={editCampaignId}
        setEditCampaignId={setEditCampaignId}
        openEditOneTimeCampaign={openEditOneTimeCampaign}
        setOpenEditOneTimeCampaign={setOpenEditOneTimeCampaign}
      />
      <Warning
        desc={t('you_wont_be_able_to_revert')}
        onConfirm={() => {
          deleteCampaignById();
        }}
        onCancel={() => {
          setDeleteCampaign(false);
          setDeleteCampaignId(null);
        }}
        open={deleteCampaign}
        loading={deleteCampaignLoading}
        heading={t('delete_campaign_confirmation')}
      />
      <Warning
        desc={t('no_template_description')}
        onConfirm={() => {
          setTemplateWarning(false);
        }}
        open={templateWarning}
        loading={markAsCanBeStartedLoading}
        heading={t('no_template_created')}
        saveText={t('ok')}
      />
      <Warning
        desc={t('you_wont_be_able_to_revert')}
        onConfirm={() => {
          markCampaignAsCanBeStarted();
        }}
        onCancel={() => {
          setMarkAsCanBeStarted(false);
          setCanBeStartedId(null);
        }}
        open={markAsCanBeStarted}
        loading={markAsCanBeStartedLoading}
        heading={t('mark_as_active_confirmation')}
      />
      <Warning
        onConfirm={() => {
          startCampaignById();
        }}
        onCancel={() => {
          setStartCampaign(false);
          setStartCampaignId(null);
        }}
        open={startCampaign}
        heading={t('start_campaign_confirmation')}
        loading={startCampaignLoading}
      />
      <Warning
        onConfirm={() => {
          blockCampaignById();
        }}
        onCancel={() => {
          setBlockCampaign(false);
          setBlockCampaignId(null);
        }}
        open={blockCampaign}
        heading={t('block_campaign_confirmation')}
        loading={blockCampaignLoading}
      />
      <Clone
        open={cloneCampaign}
        onCancel={() => setCloneCampaign(false)}
        cloneCampaignId={cloneCampaignId}
        setEditCampaignId={setEditCampaignId}
        setCampaignAction={setCampaignAction}
        setProductsData={setProductsData}
      />
      <CheckModal
        onConfirm={() => completeCampaignById()}
        heading={t('complete_campaign_confirmation')}
        open={completeCampaign}
        onCancel={() => {
          setCompleteCampaign(false);
          setCompleteCampaignId(null);
        }}
      />
      <ConnectAmazon
        open={sellerAccount}
        onConfirm={() => {
          setCampaignAction('add');
          setSellerAccount(false);
        }}
        heading={t('your_seller_central_account_is_not_connected')}
      />
      <SelectCampaign
        open={pauseOrStopCampaign}
        onCancel={() => setPauseOrStopCampaign(false)}
        onClick={handlePauseOrStopCampaign}
        pauseOrStopCampaignId={pauseOrStopCampaignId}
        pauseOrStopCampaignStatus={pauseOrStopCampaignStatus}
      />
      <QuickTutorial
        open={quickTutorial}
        onCancel={() => {
          setQuickTutorial(false);
          setIsLoginFirstTime(false);
        }}
        onClick={handleQuickTutorial}
        heading={t('welcome_to_brief_adler_text')}
        tutorialText
        loginFirstTime={isLoginFirstTime}
        videoPlayerModal={videoPlayerModal}
        setVideoPlayerModal={setVideoPlayerModal}
        videoPlayerModalLink={videoPlayerModalLink}
      />
      <CheckModal
        heading={t('campaign_uploaded_heading')}
        desc={t('campaign_uploaded_text')}
        open={campaignCreatedModal}
        onCancel={() => {
          setCampaignCreatedModal(false);
          dispatch(GetMaxiInsertByTemplateId({
            templateId: usedTemplateId as string
          }));
        }}
        okayText="OK"
        noDesc
      />
      <Drawer
        onClose={() => {
          setCampaignAction('');
          setProductsData([]);
          setEditCampaignId('');
          setStep(step - 1);
          dispatch(SetProductState({
            field: 'selectedProductIds',
            value: []
          }));
          dispatch(SetCampaignState({
            field: 'clonedCampaign',
            value: {}
          }));

          if (user?.role === 'customer') {
            if (!isEmpty(newCampaign)) {
              const tempCampaign = {
                ...newCampaign,
                status: CAMPAIGN_STATUS.DRAFT
              };

              if (campaignAction === 'add') {
                dispatch(AddAutoCampaignAPI({ newCampaign: tempCampaign }));
              } else if (campaignAction === 'edit') {
                if (JSON.stringify(newCampaign) === JSON.stringify(currentCampaign)
                    && clonedCampaign === null
                    && isEmpty(clonedCampaign)) {
                  dispatch(SetOtherNotifyState({
                    message: t('no_changes_made'),
                    type: 'info'
                  }));
                } else {
                  dispatch(UpdateAutoCampaign({ newCampaign: tempCampaign }));
                }
              }

              dispatch(SetCampaignState({
                field: 'newCampaign',
                value: {}
              }));
            }
          }

          dispatch(SetCampaignState({
            field: 'currentCampaign',
            value: {}
          }));
        }}
        title={titleComponent}
        open={campaignAction === 'add' || campaignAction === 'edit'}
        width="1123px"
      >
        <DrawerData
          campaignAction={campaignAction}
          setCampaignAction={setCampaignAction}
          setProductsData={setProductsData}
          productsData={productsData}
          setEditCampaignId={setEditCampaignId}
          editCampaignId={editCampaignId}
          setStep={setStep}
          step={step}
          videoPlayerModal={videoPlayerModal}
          setVideoPlayerModal={setVideoPlayerModal}
          videoPlayerModalLink={videoPlayerModalLink}
          setVideoPlayerModalLink={setVideoPlayerModalLink}
          setIsLoginFirstTime={setIsLoginFirstTime}
        />
      </Drawer>
      <AddMaxiAddressModal
        open={openAddMaxiAddressModal}
        saveText="Support"
        onCancel={onCancel}
        onSubmit={onSubmit}
        title={t('maxi_address')}
        loading={false}
      />
    </>
  );
};

export default CampaignsTable;
