import {
  createSlice,
  createAsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import {
  ConvertDocxFileData,
  DownloadInvoiceData,
  FilePreviewData,
  FileState,
  GetAllFilesData,
  UpdateStatusOfFileData
} from '../types/files';

import { HandleCatchBlock } from '../../utils/helpers';

const axios = axiosBaseUrl();

const initialState: FileState = {
  loading: false,
  notify: false,
  notifyMessage: '',
  getFilesSuccess: false,
  notifyType: 'error',
  success: false,
  files: [],
  totalFiles: 0,
  pdfBuffer: null,
  remainingPagesBuffer: null,
  generateFilePreviewRejected: false,
  s3FileKey: ''
};

export const ConvertDocxToPdf = createAsyncThunk(
  'file/convert-docx-to-pdf',
  async (data: ConvertDocxFileData, { rejectWithValue }) => {
    try {
      const {
        s3FileUrl,
        fileFormat
      } = data;

      const response = await axios.get('/file/convert-docx-to-pdf', {
        params: {
          s3FileUrl,
          fileFormat
        }
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const DownloadInvoicePdf = createAsyncThunk<void, DownloadInvoiceData>(
  'file/downloadInvoicePdf',
  async (data, { rejectWithValue }) => {
    try {
      const { fileId } = data;
      const params: DownloadInvoiceData = { fileId }; // Specify the type explicitly

      const getQueryString = (p: DownloadInvoiceData) => {
        const esc = encodeURIComponent;
        return Object.entries(p)
          .map(([key, value]) => `${esc(key)}=${esc(value)}`)
          .join('&');
      };

      const url = `${process.env.REACT_APP_API_URL}/auth/download-letter-and-envelop-files?${getQueryString(params)}`;

      // Create a promise that resolves when the download is complete
      return await new Promise<void>((resolve) => {
        setTimeout(() => {
          window.open(url, '_blank');
          resolve();
        }, 0);
      });
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const GetFilePreview = createAsyncThunk(
  'file/file-preview',
  async (data: FilePreviewData, { rejectWithValue }) => {
    try {
      const {
        fileFormat,
        envelopeFormat,
        templateSheets
      } = data;

      const response = await axios.get('/file/file-preview', {
        params: {
          fileFormat,
          envelopeFormat,
          templateSheets
        }
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const GetAllFiles = createAsyncThunk(
  'file/get-all-files',
  async (data: GetAllFilesData, { rejectWithValue }) => {
    try {
      const {
        skip,
        limit,
        sortBy,
        filters
      } = data;

      const response = await axios.get('/file/get-all-files', {
        params: {
          skip,
          limit,
          sortBy,
          filters: JSON.stringify(filters)
        }
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const UpdateStatusOfFile = createAsyncThunk(
  'file/update-status-of-file',
  async (data: UpdateStatusOfFileData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/file/update-status-of-file', data);

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    SetFileState(state, action: PayloadAction<{
      field: keyof FileState;
      value: FileState[keyof FileState]
    }>) {
      const updateFile = <T extends keyof FileState>(field: T, value: FileState[T]) => {
        state[field] = value;
      };
      const { field, value } = action.payload;
      updateFile(field, value as FileState[keyof FileState]);
    },
    SetFileNotifyState(state, { payload: { message, type } }) {
      state.notify = true;
      state.notifyMessage = message;
      state.notifyType = type;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(ConvertDocxToPdf.pending, (state) => {
        state.success = false;
        state.loading = true;
      })
      .addCase(ConvertDocxToPdf.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.pdfBuffer = action.payload.data.pdfBuffer;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = false;
      })
      .addCase(ConvertDocxToPdf.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(DownloadInvoicePdf.pending, (state) => {
        state.success = false;
        state.loading = true;
      })
      .addCase(DownloadInvoicePdf.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.notifyType = 'success';
        state.notify = false;
      })
      .addCase(DownloadInvoicePdf.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { message: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.message;
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetFilePreview.pending, (state) => {
        state.success = false;
        state.loading = true;
        state.generateFilePreviewRejected = false;
      })
      .addCase(GetFilePreview.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.pdfBuffer = action.payload.data.firstPageBuffer;
        state.remainingPagesBuffer = action.payload.data.remainingPagesBuffer;
        state.notifyMessage = action.payload.message;
        state.notifyType = action.payload.data.remainingPagesBuffer ? 'error' : 'success';
        state.notify = action.payload.data.remainingPagesBuffer;
        state.generateFilePreviewRejected = false;
      })
      .addCase(GetFilePreview.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }

        state.notifyType = 'error';
        state.notify = true;
        state.generateFilePreviewRejected = true;
      })
      .addCase(GetAllFiles.pending, (state) => {
        state.success = false;
        state.getFilesSuccess = false;
        state.loading = true;
      })
      .addCase(GetAllFiles.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.files = action.payload.data.files;
        state.totalFiles = action.payload.data.totalFiles;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.getFilesSuccess = true;
        state.notify = false;
      })
      .addCase(GetAllFiles.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.getFilesSuccess = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }

        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(UpdateStatusOfFile.pending, (state) => {
        state.success = false;
        state.loading = true;
      })
      .addCase(UpdateStatusOfFile.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.s3FileKey = action.payload.data.s3FileKey;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = false;
      })
      .addCase(UpdateStatusOfFile.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.getFilesSuccess = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      });
  }
});

const { actions } = fileSlice;

export const { SetFileState, SetFileNotifyState } = actions;

export default fileSlice.reducer;
