import React from 'react';
import { useTranslation } from 'react-i18next';
import type { UploadProps } from 'antd';
import { Upload } from 'antd';
import UploadImg from '../../assets/images/upload-icon.svg';
import UploadWrapper from './upload.style';

const { Dragger } = Upload;

interface UploadLogoProps {
  label?: string;
  handleChangeAttachment: (file: any) => void;
}

const props: UploadProps = {
  name: 'file',
  onDrop() { }
};

const App: React.FC<UploadLogoProps> = ({
  handleChangeAttachment
}) => {
  const { t } = useTranslation();

  return (
    <UploadWrapper>
      <Dragger
        showUploadList={false}
        onChange={handleChangeAttachment}
        customRequest={handleChangeAttachment}
        {...props}
      >
        <p className="ant-upload-drag-icon">
          <img src={UploadImg} alt={t('no_upload_icon')} />
        </p>
        <p className="ant-upload-text">
          <span>{t('click_to_upload')}</span>
          <span className="drag-drop">{t('or_drag_and_drop')}</span>
        </p>
        <p className="support-csv">{t('support_format_tsv_and_csv')}</p>
      </Dragger>

    </UploadWrapper>
  );
};

export default App;
